<template>
  <div id="m-footer">
    <ul class="footer-container">
      <li class="footer-item">
        <img class="icon" src="@/assets/img/mobile/search.svg" @click="showSearch()" />
        <span>{{ '關鍵字查詢' }}</span>
        <div v-if="searchShow" class="search-panel">
          <input class="search-text" v-model="keyword" />
          <div class="search-button" @click="search">關鍵字查詢</div>
        </div>
      </li>
      <li class="footer-item" @click="openLeaguesPanel()">
        <img
          class="icon league"
          :class="{ active: leaguesFiltered }"
          :src="
            require(`@/assets/img/mobile/icon_footer_league${leaguesFiltered ? '_on' : ''}.svg`)
          "
        />
        <span>{{ $t('Common.OtherLeague') }}</span>
      </li>
      <li v-show="page !== PageEnum.gameResult" class="footer-item" @click="onBetViewClick()">
        <div class="icon circle-bets" :class="hasBetItem ? 'hasBetItem' : ''">
          <span class="num">{{ betCartLength }}</span>
        </div>
        <span :style="hasStrayStyle">{{ $lib.trunc(strayOdd, 2) || $t('Common.ClickBet') }}</span>
      </li>
      <li class="footer-item" @click="onBetRecordViewClick()">
        <img class="icon" src="@/assets/img/mobile/icon_footer_record.svg" />
        <span>{{ $t('Common.BetHistoryRecord') }}</span>
        <span v-if="unsettledBetCount" class="unsettledBetCount">{{ unsettledBetCount }}</span>
      </li>
      <li class="footer-item" @click="openLive()">
        <img class="icon" src="@/assets/img/mobile/icon_footer_palysvg.svg" />
        <span>{{ $t('Common.Live') }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from 'vuex';
  import { PageEnum } from '@/config/mobile/enum';
  import { mapState } from 'vuex/dist/vuex.common.js';

  export default {
    props: {
      page: {
        type: Number,
        default: PageEnum.game,
      },
      hasLeagueFiltered: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        PageEnum,
        searchShow: false,
        keyword: '',
      };
    },
    computed: {
      ...mapState(['darkTheme']),
      ...mapGetters(['siteLiveOpen']),
      leaguesFiltered() {
        return this.$store.state.Game.selectLeagueIDs2.length > 0;
      },
      betCartStore() {
        return this.$store.state.BetCart;
      },
      betCartLength() {
        return this.betCartStore.betCartList.length;
      },
      hasBetItem() {
        return this.betCartLength > 0;
      },
      strayOdd() {
        return this.betCartStore.strayOdd;
      },
      hasStrayStyle() {
        const onColor = !this.darkTheme ? '#ff9600' : '#ffde00';
        const offColor = !this.darkTheme ? '#000000' : '#bbbbbb';
        return {
          color: this.strayOdd ? onColor : offColor,
        };
      },
      unsettledBetCount() {
        return this.betCartStore.unsettledBetCount;
      },
    },
    methods: {
      ...mapMutations(['showLoading', 'hideLoading']),
      ...mapActions('Game', ['showChampionTable', 'openPromotionPage']),
      openLive() {
        this.$router.push('/lives');
      },
      openLiveOld2() {
        const { $store } = this;
        this.showLoading();
        $store
          .dispatch('Game/updateLives')
          .then(() => {
            $store.commit('Game/showLives');
          })
          .finally(() => {
            this.hideLoading();
          });
      },
      openLiveOld() {
        const newWindow = window.open();
        newWindow.opener = null;
        this.$store.commit('setLoading', true);
        this.$store
          .dispatch('Game/GetLiveURL')
          .then((res) => {
            const url = res.data;
            if (url && newWindow) {
              newWindow.location.href = url;
            }
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      openLeaguesPanel() {
        this.$emit('openLeaguesPanel');
      },
      onBetViewClick() {
        this.hasBetItem && this.$emit('openBetInfoPopup');
      },
      onBetRecordViewClick() {
        this.$emit('openBetRecordView');
      },
      goNewsPage() {
        this.$router.push('/News');
      },
      openChampions() {
        this.$router.push('/champions');
      },
      showSearch() {
        this.searchShow = !this.searchShow;
      },
      search() {
        if (!this.keyword) {
          this.$notify.error({
            message: '請輸入查詢關鍵字',
          });
          return;
        }
        this.$store.dispatch('Game/updateGameSearch', this.keyword);
        this.searchShow = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';

  @keyframes footer-promotion {
    100% {
      transform: scale(0.84);
    }
  }

  .search-panel {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 4px;
    position: absolute;
    width: 240px;
    bottom: 100%;
    z-index: 99999;
    padding: 8px 16px;
    background-color: #666;
    border-radius: 4px;
  }

  .search-text {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100px;
  }

  .search-button {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 2px 4px 1px;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: #ddd;

    &:hover {
      background-color: #fff;
      cursor: pointer;
    }
  }

  #m-footer {
    ul.footer-container {
      width: 100%;
      height: 4.8rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #eee;
      border-top: 1px solid #ccc;
      box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.1);

      li.footer-item {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        font-size: 0.923rem;
        padding: 3px;
        color: #5f5f5f;

        &:active {
          background-color: #ddd;
        }
        .icon {
          margin-bottom: 6px;
          width: 1.825rem;
          height: auto;

          &.promotion {
            position: relative;
            top: 7px;
            width: 3rem;
            animation: footer-promotion 1.2s ease-in-out 1.2s infinite alternate;
          }
        }
        .circle-bets {
          width: 2.2rem;
          height: 2.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid #5f5f5f;
          border-radius: 50%;
          text-align: center;
          span.num {
            color: #5f5f5f;
            font-size: 1.45rem;
            font-weight: bold;
            line-height: normal;
          }
          &.hasBetItem {
            border: 2px solid #ff9600;
            span.num {
              color: #ff9600;
            }
          }
        }

        .unsettledBetCount {
          position: absolute;
          top: 5px;
          left: 50%;
          transform: translateX(calc(-50% + 20px));
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100em;
          padding: 2px 3px;
          min-width: 20px;
          min-height: 20px;
          color: #ffffff;
          font-size: 0.923rem;
          letter-spacing: 1px;
          box-shadow: 1px 1px 6px rgb(0 0 0 / 30%);
          background-color: rgba(255, 53, 53, 0.9);
        }
      }
    }
  }

  #app[data-theme^='dark'] {
    #m-footer {
      ul.footer-container {
        background-color: #474747;
        border-color: #585858;
        li.footer-item {
          color: #bbb;

          .icon {
            filter: grayscale(1) brightness(3);
            &.circle-bets {
              &.hasBetItem {
                border: 2px solid #ffde00;
                span.num {
                  color: #ffde00;
                }
                filter: unset !important;
              }
            }
            &.league.active {
              filter: unset !important;
            }
            &.promotion {
              filter: unset;
            }
          }
          &:active {
            background-color: #333;
          }
        }
      }
    }
  }
</style>
