<template>
  <div class="games-menu" :class="{ collapse: isCollapse }">
    <div class="menu-header">
      <div v-if="!isCollapse" class="menu-types">
        <div
          v-for="type in gameTypeList"
          :key="type.key"
          class="menu-type"
          :class="{ active: type.key === selectGameType }"
          @click="onSelectType(type)"
          >{{ type.value }}</div
        >
      </div>
      <i
        class="collapse-icon"
        :class="isCollapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
        @click="toggleCollaspe()"
      />
    </div>
    <div
      v-if="isCollapse"
      class="menu-active-type"
      @mouseenter="showTypeMenu($event)"
      @mouseleave="onLeaveItem()"
    >
      <div class="type-name">{{ selectGameTypeName }}</div>
    </div>
    <div class="menu-search">
      <input class="menu-search-text" v-model="keyword" />
      <div class="menu-search-button" @click="search">關鍵字查詢</div>
    </div>
    <div class="menu-cats">
      <template v-for="cat in catList">
        <div
          v-if="cat.count"
          :key="cat.catid"
          class="menu-cat"
          :class="{ active: cat.catid === selectCatID }"
          @click="onSelectCat(cat)"
          @mouseenter="showWagerMenu(cat, $event)"
          @mouseleave="onLeaveItem()"
        >
          <img
            v-if="cat.catid === GAME_CHAMPION_CAT"
            class="cat-icon champion"
            src="@/assets/img/champion.png"
          />
          <img v-else class="cat-icon" :src="getCatIcon(cat)" />
          <div class="cat-name">{{ cat.catName }}</div>
          <div class="cat-count">{{ cat.count }}</div>
        </div>
        <div
          v-if="cat.catid === selectCatID && cat.Items.length && isShowWagers"
          :key="`${cat.catid}-wagers`"
          class="menu-wagers"
        >
          <div
            v-for="wager in cat.Items"
            :key="`${cat.catid}-${wager.WagerTypeKey}`"
            class="menu-wager"
            :class="{ active: wager.WagerTypeKey === selectWagerTypeKey }"
            @click="onSelectWager(cat, wager.WagerTypeKey)"
          >
            <div class="wager-name">{{ wager.WagerTypeName }}</div>
            <div class="wager-count">{{ wager.count }}</div>
          </div>
        </div>
      </template>
    </div>
    <div class="menu-promotion">
      <img class="promotion-image" src="@/assets/promotion/menu.gif" @click="showPopularTable()" />
    </div>
    <div
      v-show="isShowMenu"
      id="app-menu"
      class="app-menu"
      tabindex="-1"
      @mouseenter="onEnterMenu()"
      @mouseleave="onLeaveMenu()"
    />
  </div>
</template>

<script>
  import { GAME_FAVORITE_CAT, GAME_HOT_CAT, GAME_LOTTERY_CAT, GAME_CHAMPION_CAT } from '@/config';
  import { mapActions } from 'vuex';
  import { getMenuIconByCatID } from '@/utils/SportLib';

  const SESSION_GameType = 'GameType';
  const SESSION_CatID = 'CatID';
  const SESSION_WagerTypeKey = 'WagerTypeKey';

  export default {
    props: {
      ok: { type: Boolean, value: false },
    },
    data() {
      return {
        isCollapse: false,
        isShowMenu: false,
        intervalID1: null,
        intervalID2: null,
        intervalID3: null,
        GAME_CHAMPION_CAT,
        GAME_LOTTERY_CAT,
        GAME_HOT_CAT,
        isShowWagers: true,
        keyword: '',
      };
    },
    computed: {
      gameStore() {
        return this.$store.state.Game;
      },
      gameTypeList() {
        const { GameTypeList } = this.gameStore;
        return GameTypeList.filter(({ key }) => key >= 2);
      },
      selectGameType() {
        return this.gameStore.selectGameType;
      },
      selectGameTypeName() {
        const { selectGameType } = this;
        const gameType = this.gameTypeList.find((typeItem) => typeItem.key === selectGameType);
        return gameType ? gameType.value : '';
      },
      catList() {
        return [
          {
            catid: GAME_FAVORITE_CAT,
            catName: this.$t('Common.Collect'),
            count: this.$store.state.Setting.UserSetting.favorites.length,
          },
          ...this.$store.getters['Game/selectMenuCatList'],
        ];
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      tableSort() {
        return this.$store.state.Setting.UserSetting.tableSort;
      },
      isCallGameDetailAPI() {
        return this.gameStore.isCallGameDetailAPI;
      },
      hasHot() {
        const list = this.catList.map((item) => item.catid);
        return list.includes(888888);
      },
    },
    watch: {
      tableSort: {
        handler() {
          this.updateGameDetail({ loading: true, clear: true });
        },
      },
      isCallGameDetailAPI: {
        handler() {
          this.updateGameDetail({ loading: false, clear: false });
        },
      },
    },
    methods: {
      ...mapActions('Game', ['getCatMenuKeys', 'showTable', 'showPopularTable']),
      storeToSession() {
        sessionStorage.setItem(SESSION_GameType, this.selectGameType);
        sessionStorage.setItem(SESSION_CatID, this.selectCatID);
        sessionStorage.setItem(SESSION_WagerTypeKey, this.selectWagerTypeKey);
      },
      restoreFromSession() {
        const selectGameType = +sessionStorage.getItem(SESSION_GameType);
        const selectCatID = +sessionStorage.getItem(SESSION_CatID);
        let wagerKey = +sessionStorage.getItem(SESSION_WagerTypeKey);
        if (!wagerKey) {
          wagerKey = null;
        }

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType,
          selectCatID,
          selectWagerTypeKey: wagerKey,
        });
      },
      updateGameDetail({ loading = false, clear = false }) {
        if (loading) {
          this.$store.commit('setLoading', true);
        }
        return this.$store.dispatch('Game/updateGameDetail', { clear }).finally(() => {
          if (loading) {
            this.$store.commit('setLoading', false);
          }
        });
      },
      updateGameDetailSmall() {
        return this.$store.dispatch('Game/updateGameDetailSmall');
      },
      getCatIcon(catItem) {
        const { catid: catID } = catItem;
        const icon = getMenuIconByCatID(catID);
        if (icon) {
          return require('@/assets/img/common/menuIcon/' + icon);
        } else {
          return '';
        }
      },
      toggleCollaspe() {
        this.isCollapse = !this.isCollapse;
      },
      showTypeMenu(event) {
        if (!this.isCollapse) return;
        const menu = document.getElementById('app-menu');
        if (!menu) return;
        menu.textContent = '';

        const { gameTypeList, selectGameType } = this;
        const fragment = new DocumentFragment();
        const listDiv = document.createElement('div');
        listDiv.classList = 'app-type-list';
        for (const typeItem of gameTypeList) {
          const { key: typeKey } = typeItem;
          const itemDiv = document.createElement('div');
          itemDiv.classList = 'app-type-item';
          if (typeKey === selectGameType) {
            itemDiv.classList.add('active');
          }
          itemDiv.onclick = () => {
            this.onSelectType(typeItem);
            this.isShowMenu = false;
          };
          const nameDiv = document.createElement('div');
          nameDiv.classList = 'app-type-name';
          nameDiv.textContent = typeItem.value;
          itemDiv.append(nameDiv);
          listDiv.append(itemDiv);
        }
        fragment.append(listDiv);
        menu.append(fragment);

        const style = menu.style;
        const target = event.target;

        style.top = 110 + target.offsetTop - 16 + 'px';
        style.left = target.offsetLeft + target.offsetWidth - 2 + 'px';

        this.isShowMenu = true;
        this.$nextTick(() => {
          menu.focus();
        });
      },
      showWagerMenu(catItem, event) {
        if (!this.isCollapse) return;
        const menu = document.getElementById('app-menu');
        if (!menu) return;
        menu.textContent = '';

        const { Items: wagerList } = catItem;
        if (!wagerList) {
          this.isShowMenu = false;
          return;
        }

        const { selectWagerTypeKey } = this;
        const fragment = new DocumentFragment();
        const listDiv = document.createElement('div');
        listDiv.classList = 'app-wager-list';
        for (const wagerItem of wagerList) {
          const { WagerTypeKey: wagerKey } = wagerItem;
          const itemDiv = document.createElement('div');
          itemDiv.classList = 'app-wager-item';
          if (wagerKey === selectWagerTypeKey) {
            itemDiv.classList.add('active');
          }
          itemDiv.onclick = () => {
            this.onSelectWager(catItem, wagerKey);
            this.isShowMenu = false;
          };
          const nameDiv = document.createElement('div');
          nameDiv.classList = 'app-wager-name';
          nameDiv.textContent = wagerItem.WagerTypeName;
          const countDiv = document.createElement('div');
          countDiv.classList = 'app-wager-count';
          countDiv.textContent = wagerItem.count;
          itemDiv.append(nameDiv);
          itemDiv.append(countDiv);
          listDiv.append(itemDiv);
        }
        fragment.append(listDiv);
        menu.append(fragment);

        const target = event.target;
        const style = menu.style;
        style.top = 108 + target.offsetTop - 8 * (wagerList.length - 1) + 'px';
        style.left = target.offsetLeft + target.offsetWidth - 2 + 'px';

        this.isShowMenu = true;
        this.$nextTick(() => {
          menu.focus();
        });
      },
      onEnterMenu() {
        this.isShowMenu = true;
      },
      onLeaveMenu() {
        this.isShowMenu = false;
      },
      onLeaveItem() {
        this.isShowMenu = false;
      },
      onSelectType(typeItem) {
        const { key: typeKey } = typeItem;
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        if (typeKey === selectGameType) {
          return;
        }

        let catID = null;
        let wagerKey = null;
        if ([GAME_FAVORITE_CAT].includes(selectCatID)) {
          catID = selectCatID;
        } else {
          const menuData = this.gameStore.FullMenuList.find(
            (menuData) => menuData.GameType === typeKey
          );
          if (menuData) {
            const { item: catList } = menuData.LeftMenu;
            if (catList?.length) {
              const catItem = catList.find((catItem) => catItem.catid === selectCatID);
              if (catItem) {
                catID = selectCatID;
                const { Items: wagerList } = catItem;
                if (wagerList?.length) {
                  const wagerItem = wagerList.find(
                    (wagerItem) => wagerItem.WagerTypeKey === selectWagerTypeKey
                  );
                  if (wagerItem) {
                    wagerKey = wagerItem.WagerTypeKey;
                  } else {
                    wagerKey = wagerList[0].WagerTypeKey;
                  }
                }
              } else {
                const catData0 = catList[0];
                catID = catData0.catid;
                const { Items: wagerList } = catData0;
                if (wagerList?.length) {
                  wagerKey = wagerList[0].WagerTypeKey;
                }
              }
            }
          }
        }

        if (catID === null) {
          catID = 1;
          wagerKey = 1;
        }

        this.isShowWagers = true;

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType: typeKey,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });
        this.$store.commit('MoreGame/closeMoreGameList');
        window.OddData.clear();
        this.$emit('ChangeCat');
        this.updateGameDetail({ loading: true, clear: true }).then((res) => {
          this.$store.dispatch('Game/updateGameMenuList').then((res) => {});
        });
      },
      onSelectCat(catItem) {
        const { catid: catID, Items: wagerList } = catItem;
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        const wagerKey = wagerList?.length ? wagerList[0].WagerTypeKey : null;
        if (catID === selectCatID) {
          this.isShowWagers = !this.isShowWagers;
          return;
        }
        if (catID === selectCatID && wagerKey === selectWagerTypeKey) {
          this.isShowWagers = !this.isShowWagers;
          return;
        }
        this.isShowWagers = true;
        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });
        this.$store.commit('MoreGame/closeMoreGameList');
        window.OddData.clear();
        this.$emit('ChangeCat');
        this.updateGameDetail({ loading: true, clear: true });
      },
      onSelectWager(catItem, wagerKey) {
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        const { catid: catID } = catItem;
        if (catID === selectCatID && wagerKey === selectWagerTypeKey) {
          return;
        }

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });
        this.$store.commit('MoreGame/closeMoreGameList');
        this.updateGameDetail({ loading: true, clear: true });
      },
      onWindowFocus() {
        const { $router, $store } = this;
        const { query } = $router.currentRoute;
        const { t, c, p } = query;
        if (p === 'olympic') {
          this.getCatMenuKeys({ cat: 86 }).then((keys) => {
            this.showTable(keys);
          });
          $router.replace({ query: null });
        } else if (t && c) {
          const myT = Number(t);
          const myC = Number(c);
          console.log(myT, myC);
          $store.commit('Game/setCatIDAndGameTypeAndWagerType', {
            selectGameType: myT,
            selectCatID: myC,
            selectWagerTypeKey: 1,
          });
          window.OddData.clear();
          this.$emit('ChangeCat');
          $store.commit('MoreGame/closeMoreGameList');
          $store.commit('setLoading', true);
          $store.dispatch('Game/updateGameDetail', { clear: true }).finally(() => {
            $store.commit('setLoading', false);
          });
          $router.replace({ query: null });
        }
      },
      search() {
        if (!this.keyword) {
          this.$notify.error({
            message: '請輸入查詢關鍵字',
          });
          return;
        }
        this.$store.dispatch('Game/updateGameSearch', this.keyword);
      },
    },
    mounted() {
      const gameType = sessionStorage.getItem(SESSION_GameType);
      const catID = sessionStorage.getItem(SESSION_CatID);
      if (gameType && catID) {
        this.restoreFromSession();
      }

      this.updateGameDetail({ loading: true, clear: true });

      this.intervalID1 = setInterval(() => {
        this.updateGameDetailSmall();
      }, 10000);

      this.intervalID2 = setInterval(() => {
        this.$store.dispatch('Game/updateGameMenuList', false);
      }, 20000);

      this.intervalID3 = setInterval(() => {
        this.$store.dispatch('updateUserBalance');
      }, 60000);

      window.addEventListener('beforeunload', this.storeToSession);
      window.addEventListener('focus', this.onWindowFocus);
    },
    beforeDestroy() {
      clearInterval(this.intervalID1);
      clearInterval(this.intervalID2);
      clearInterval(this.intervalID3);
      window.removeEventListener('beforeunload', this.storeToSession);
      window.removeEventListener('focus', this.onWindowFocus);
    },
  };
</script>

<style lang="scss">
  @import '@/assets/sass/theme/mixin.scss';

  @keyframes menu-champion {
    100% {
      transform: translate(-50%, -50%) scale(0.8);
    }
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 4px;
  }
  /*
  ::-webkit-scrollbar:horizontal {
    height: 12px;
  }*/

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .menu-search {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 4px;
    position: relative;
    width: 100%;
    padding: 4px;
  }

  .menu-search-text {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100px;
  }

  .menu-search-button {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 2px 4px 1px;
    border: 1px solid #444;
    border-radius: 4px;

    &:hover {
      background-color: #fff;
      cursor: pointer;
    }
  }

  .games-menu {
    flex: 0 0 180px;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $GamesNavMenu_BG_COLOR;

    > .menu-header {
      @include nav-headrtBgcolor();

      flex: 0 0 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #ddd;

      > .menu-types {
        flex-grow: 1;
        display: flex;

        > .menu-type {
          flex-grow: 1;
          margin: 0 4px;
          padding-bottom: 2px;
          border-bottom: 2px solid transparent;
          text-align: center;
          cursor: pointer;

          > .type-name {
            display: inline-block;
            height: 24px;
            line-height: 24px;
            padding: 0 4px;
            border-bottom: 2px solid #ddd;
            color: #fff;

            &:hover {
              border-bottom-color: #fff;
            }
          }

          &.active {
            color: #ddd;
            border-bottom-color: #ddd;

            &:hover {
              border-bottom-color: #fff;
            }
          }

          &:hover {
            color: #fff;
          }
        }
      }

      > .collapse-icon {
        padding: 0 4px;
        font-size: 20px;
        cursor: pointer;

        &:hover {
          color: #fff;
        }
      }
    }

    > .menu-cats {
      flex: 1 0 fit-content;
      width: 100%;

      > .menu-cat {
        position: relative;
        height: 35px;
        border-bottom: 1px solid #bbb;
        line-height: 35px;
        cursor: pointer;

        > .cat-icon {
          position: absolute;
          width: 20px;
          left: 20px;
          top: 50%;
          transform: translate(-50%, -50%);

          &.popular {
            width: 28px;
          }

          &.champion {
            width: 32px;
            animation: menu-champion 1.2s ease-in-out 1.2s infinite alternate;
          }
        }

        > .cat-name {
          @include nav-TopTextcolor();
          position: absolute;
          width: 100px;
          left: 40px;
          top: 50%;
          transform: translateY(-50%);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > .cat-count {
          @include nav-TopTextNumbercolor();
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translate(50%, -50%);
        }

        &.active {
          background-color: #eee;
        }

        &:hover,
        &:active {
          background-color: #fff;
        }
      }

      > .menu-wagers {
        height: fit-content;

        > .menu-wager {
          position: relative;
          height: 35px;
          border-bottom: 1px solid #bbb;
          background-color: #eee;
          line-height: 35px;
          cursor: pointer;

          > .wager-name {
            @include nav-TopTextcolor();
            position: absolute;
            width: 100px;
            left: 40px;
            top: 50%;
            transform: translateY(-50%);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          > .wager-count {
            @include nav-TopTextcolor();
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translate(50%, -50%);
          }

          &.active {
            .wager-name {
              color: #3fa381;
            }
          }

          &:hover {
            background-color: #fff;
          }
        }
      }
    }

    > .menu-promotion {
      flex: 1 0 fit-content;
      display: flex;
      flex-flow: column nowrap;
      justify-content: end;
      width: 100%;

      > .promotion-image {
        width: 100%;
        cursor: pointer;
      }
    }

    &.collapse {
      flex-basis: 64px;

      .menu-search {
        display: none;
      }

      > .menu-active-type {
        @include base-background();
        position: relative;
        height: 35px;
        color: #fff;
        line-height: 35px;
        text-align: center;
        cursor: pointer;
      }

      > .menu-cats {
        flex: 1 0 fit-content;
        width: 100%;

        > .menu-cat {
          > .cat-icon {
            left: 50%;
          }

          > .cat-name {
            display: none;
          }

          > .cat-count {
            display: none;
          }
        }

        > .menu-wagers {
          display: none;
        }
      }
    }
  }

  #app[data-theme^='dark'] {
    .games-menu {
      background: $GamesNavMenu_BG_COLOR1;
      > .menu-cats {
        flex: 1 0 fit-content;
        width: 100%;

        > .menu-cat {
          border-bottom: 1px solid #666;

          &.active {
            background-color: #222;
          }

          &:hover,
          &:active {
            background-color: #111;
          }
        }
        > .menu-wagers {
          > .menu-wager {
            border-bottom: 1px solid #666;
            background-color: #222;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  @import '@/assets/sass/theme/mixin.scss';

  #app-menu {
    position: fixed;
    z-index: 10;

    &:focus {
      outline: none;
    }
  }

  .app-type-list {
    background-color: #ddd;
    border-radius: 4px;
    box-shadow: -2px 0px 4px #aaa, 2px 2px 4px #aaa;
    overflow: hidden;
  }

  .app-type-item {
    display: flex;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #bbb;
    cursor: pointer;

    &.active {
      background-color: #eee;

      .app-type-name {
        color: $nav_submenu_active_text;
      }
    }

    &:hover {
      background-color: #fff;
    }
  }

  .app-type-name {
    @include nav-TopTextcolor();
    flex-grow: 1;
    margin: 0 24px;
  }

  .app-wager-list {
    background-color: #ddd;
    border-radius: 4px;
    box-shadow: -2px 0px 4px #aaa, 2px 2px 4px #aaa;
    overflow: hidden;
  }

  .app-wager-item {
    display: flex;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #bbb;
    cursor: pointer;

    &.active {
      background-color: #eee;

      .app-wager-name {
        color: $nav_submenu_active_text;
      }
    }

    &:hover {
      background-color: #fff;
    }
  }

  .app-wager-name {
    @include nav-TopTextcolor();
    flex-grow: 1;
    margin: 0 0 0 24px;
  }

  .app-wager-count {
    @include nav-TopTextNumbercolor();
    margin: 0 24px;
  }

  #app[data-theme^='dark'] {
    .app-type-list,
    .app-wager-list {
      box-shadow: -2px 0px 4px #333, 2px 2px 4px #333;
      background-color: $GamesNavMenu_BG_COLOR1;
    }

    .app-type-item,
    .app-wager-item {
      border-bottom: 1px solid #666;

      &.active {
        background-color: #222;
      }

      &:hover {
        background-color: #111;
      }
    }

    .menu-search-button {
      border: 1px solid #444;
      color: #eee;

      &:hover {
        background-color: #111;
      }
    }
  }
</style>
